import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { AccordionModule } from 'primeng/accordion';
import { TagModule } from 'primeng/tag';
import { constants} from '../../../../../shared/constants/constants'
import { HeadingComponent } from '../../../../../shared/components/heading/heading.component';
import { EducationService} from '../../../../../shared/services/education.service';
import { Education, Educations } from '../education.interface';
import { RouterModule } from '@angular/router';
import { ConfirmationDialogService } from '../../../../../shared/services/confirmation-dialog.service';
import {ReviewerService} from '../../../../../shared/services/reviewer.service'
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DocumentService } from '../../../../../shared/services/document-service';
import { UserService } from '../../../../../shared/services/user.service';
import { ShareProfileDataComponent } from '../share-profile-data/share-profile-data.component';
import { Tenant } from '../../../../../store/reviewer/reviewer.interface';
import { ToastService } from '../../../../../shared/services/toast.service';
@Component({
  selector: 'app-listing',
  standalone: true,
  imports: [CommonModule, ButtonModule,RouterModule, FloatLabelModule, CalendarModule, InputTextModule, DropdownModule, HeadingComponent, MultiSelectModule, AccordionModule, TagModule],
  templateUrl: './listing.component.html',
  styleUrl: './listing.component.scss'
})
export class ListingComponent implements OnInit {
  @Input() from: string = '';
  @Output() showCreateEducationForm: EventEmitter<any> = new EventEmitter();
  @Output() back: EventEmitter<any> = new EventEmitter();
  @Output() next: EventEmitter<any> = new EventEmitter();
  headingButton = {
    url: '/reviewer/profile/education/create',
    label: 'Add a school'
  }
  educations:Educations = [];
  secureBackgroundStatus = constants.secureBackgroundStatus;
  educationDataLength = -1;
  
  ref: DynamicDialogRef | undefined;
  selectedTenant: Tenant | undefined;
  resume: any = [];
  
  constructor(
    private educationService: EducationService,
    private confirmationDialogService: ConfirmationDialogService,
    private reviewerService:ReviewerService,
    private dialogService: DialogService,
    private toast: ToastService,
    private userService: UserService,
    private documentService: DocumentService
  ) {}

  editMode(event: any) {
    event.stopPropagation();
  }

  ngOnInit() {
    this.getEducations();
    const tenants = this.reviewerService.getReviewerTenants();
    if(this.from === 'onboarding' && tenants.length > 0){
      this.selectedTenant = tenants.find((tenant) => tenant.reviewerStatus === constants.userVerificationStatus.pendingAdminVerification || tenant.reviewerStatus === constants.userVerificationStatus.verified);
      if (this.selectedTenant) {
        this.getReviewerResumesAndCertificates();        
      }
    }
  }

  getReviewerResumesAndCertificates(){
    this.documentService.getFirmReviewerDocuments({firmId: this.selectedTenant!.firm._id, reviewerId: this.userService.getUserId()}).subscribe((docs) => {
      this.resume = docs.filter((doc: any) => doc.documentType === 'CERTIFICATION' || doc.documentType === 'RESUME');
      this.showShareProfileTenantPopup();
    });
  }

  showShareProfileTenantPopup(){
    if(this.resume.length){
      let data = {
        tenantId: this.selectedTenant?.firm._id,
        documents: this.resume,
      };
      this.ref = this.dialogService.open(ShareProfileDataComponent, {
        data: data,
        header: 'Share Profile Across Tenants',
        width: '30vw',
        contentStyle: { overflow: 'hidden' },
        breakpoints: {
          '960px': '75vw',
          '640px': '90vw'
        },
      });
      this.ref.onClose.subscribe((data: any) => {
        console.log(data);
        if (data) {   
          this.toast.showSuccess('Resume/Certificates Added Successfully');
        }
      });
    }
  }

  getEducations(){
    this.educationService.getEducations().subscribe((response:Educations)=>{
      this.educations = response;
      this.educationDataLength = response.length;
    })
  }

  status(data:Education): string{
    if(data.secureBackgroundResult === this.secureBackgroundStatus.meetsRequirement){
      return 'Verified';
    }else if(data.secureBackgroundStatus=== this.secureBackgroundStatus.pending || data.secureBackgroundResult=== this.secureBackgroundStatus.needsFurtherReview){
      return 'Verification in progress';
    }else if(data.secureBackgroundResult === this.secureBackgroundStatus.doesntMeetsRequirement){
      return ' Not verified';
    }else{
      return '';
    }
  }

  showCreateSchool(){
    this.showCreateEducationForm.emit();
  }

  submit(type:any){
    if (type === 'back') {
      this.back.emit();
    } else {
      this.reviewerService.fetchReviewerAndUpdate();
      this.next.emit();
    }
  }

  onDelete($event: Event, education: Education) {
    $event.stopPropagation();
    this.confirmationDialogService.confirm({
      target: $event.target as EventTarget,
      message:`Are you sure you want to delete ${education.school.name} ?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        this.educationService.deleteEducation(education._id).subscribe({
          next: (res) => {
            this.ngOnInit();
          },
        });
      },
    });
  }
}
