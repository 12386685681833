<app-heading [title]="from === 'profile' ? 'General Information' : 'Contact Information' " [button]="false" [isbreadcrumb]="false" [showToggle]="false"></app-heading>


<form [formGroup]="profileForm">
  @if(from === 'onboarding'){
  <div class="grid xl:w-9 w-full pt-4">
    <div class="lg:col-6 col-12">
      <p-floatLabel>
        <input
          formControlName="firstName"
          type="text"
          pInputText
          id="firstname"
        />
        <label for="firstname">First Name</label>
      </p-floatLabel>
    </div>
    <div class="lg:col-6 col-12">
      <p-floatLabel>
        <input
          formControlName="lastName"
          type="text"
          pInputText
          id="lastname"
        />
        <label for="lastname">Last Name</label>
      </p-floatLabel>
    </div>
    <div class="lg:col-6 col-12" >
      <p-floatLabel>
        <input
          formControlName="email"
          type="email"
          pInputText
          inputId="Email"
        />
        <label for="Email">Email</label>
      </p-floatLabel>
    </div>
    <div class="lg:col-6 col-12" >
      <a *ngIf="from === 'onboarding' && mergeInvite"
      class="merge-link"
        (click)="mergeAccount()"
      >Already have a Staftr account?</a>
    </div>
    @if(showPasswordFeld){
    <div class="col-12" >
      <p-floatLabel>
        <p-password formControlName="password" [feedback]="false" [toggleMask]="true" styleClass="w-full" />
        <label for="Email">Password</label>
      </p-floatLabel>
    </div>
    }
  </div>
  }
  <div
    formGroupName="profile"
    class="flex flex-column justify-content-between h-page-half"
  >
  @if(from === 'onboarding'){
    <div formGroupName="contact" class="grid xl:w-9 w-full pt-4">
      <div class="lg:col-6 col-12">
        <div class="flex gap-3">
          <p-floatLabel class="w-full">
            <input (input)="formatContact($event)" maxlength="12" placeholder="000-000-0000" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" formControlName="home" type="tel" pInputText id="phone" />
            <label for="phone">Phone</label>
          </p-floatLabel>
          <p-button
            label="Get Code"
            rounded="true"
            size="large"
            styleClass="white-space-nowrap"
            [disabled]="isPhoneVerified"
            (onClick)="sendVerificationCode()"
          ></p-button>
        </div>
      </div>
      @if(!isPhoneVerified){
      <div class="lg:col-6 col-12">
        <div class="flex gap-3">
          <p-floatLabel class="w-full">
            <input type="text" pInputText id="verificationCode" #verificationCodeInput />
            <label for="verificationCode">Verification Code</label>
          </p-floatLabel>
          <p-button
            label="Verify code"
            rounded="true"
            size="large"
            styleClass="white-space-nowrap"
            (onClick)="verify(verificationCodeInput.value)"
          ></p-button>
        </div>
      </div>
      }
      <div class="lg:col-6 col-12">
        <p-floatLabel>
          <input formControlName="street" type="text" pInputText id="address" />
          <label for="address">Address</label>
        </p-floatLabel>
      </div>
      <div class="lg:col-6 col-12">
        <p-floatLabel>
          <input
            formControlName="city"
            inputId="city"
            placeholder="City"
            pInputText
          />
          <label for="city">City</label>
        </p-floatLabel>
      </div>
      <div class="lg:col-6 col-12">
        <p-floatLabel>
          <input
            formControlName="zipCode"
            maxlength="10"
            type="number"
            pInputText
            id="address"
          />
          <label for="zipCode">Zip Code</label>
        </p-floatLabel>
      </div>
      <div class="lg:col-6 col-12">
        <p-floatLabel>
          <p-dropdown
            [options]="stateDropDown"
            formControlName="state"
            inputId="states"
            appendTo="body"
            optionLabel="name"
          ></p-dropdown>
          <label for="states">State</label>
        </p-floatLabel>
      </div>
    </div>
  }
    <div class="grid xl:w-10 flex-column w-full pt-4">
      <div class="col-6 flex flex-row">
        <div class="pl-0 col-10">
          <p-floatLabel>
            <p-multiSelect
              formControlName="searchMarkets"
              display="chip"
              [showClear]="true"
              [options]="searchMarketDropdown"
              optionLabel="label"
              inputId="searchMarket"
              (onChange)="onSearchMarketDropdownChange($event)"
            />
            <label for="searchMarket">Search Markets</label>
          </p-floatLabel>
        </div>
        <div class="col-2 flex align-items-center">
          <span 
            pTooltip="Staftr uses Search Markets to geographically target job offers to candidates in the correct regions (when required). Please select any search markets you are willing and able to work in." 
            tooltipPosition="top" 
            icon="true"
            class="material-symbols-outlined cursor-pointer text-primary-600">
            info
          </span>
        </div>
      </div>
    
      <div class="col-6 flex flex-row">
        <div class="pl-0 col-10">
          <p-floatLabel>
            <p-multiSelect
              formControlName="jobCategories"
              display="chip"
              [options]="jobType"
              placeholder="Click all that apply"
              optionLabel="label"
              inputId="jobTypes"
              (onChange)="onJobCategoryDropdownChange($event)"
            />
            <label for="jobTypes">Job Categories</label>
          </p-floatLabel>
        </div>
        <div class="col-2 flex align-items-center">
          <span 
            pTooltip="Job Types help Staftr firms target job offers at specific industries and/or specialities. Please select any Job Types you are willing and able to work in." 
            tooltipPosition="top" 
            icon="true"
            class="material-symbols-outlined cursor-pointer text-primary-600">
            info
          </span>
        </div>
      </div>
    </div>
  </div>
    <div class="flex gap-3 mt-3">
      <p-button
        label="Discard"
        rounded="true"
        outlined="true"
        size="large"
        [disabled]="from === 'onboarding'"
        styleClass="white-space-nowrap"
      ></p-button>
      <p-button
        [label]="from === 'onboarding' ? 'Next' : 'Save'"
        rounded="true"
        size="large"
        [disabled]="!profileForm.valid"
        styleClass="white-space-nowrap"
        (onClick)="onSubmit()"
      ></p-button>
    </div>
</form>
